$('.f3-slick-carousel').slick({
  centerMode: false,
  autoplay: true,
  autoplaySpeed: 1000,
  centerPadding: '80px',
  slidesToShow: 4,
  arrows: false,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        arrows: false,
        centerMode: true,
        centerPadding: '40px',
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 480,
      settings: {
        arrows: false,
        centerMode: true,
        centerPadding: '40px',
        slidesToShow: 1,
      },
    },
  ],
});
$('.f3-slick-carousel').removeClass('d-none');

let botCheckTimes = 0;
let maxBotCheckTimes = 25;

function poll(guid) {
  setTimeout(function () {
    $.ajax({
      url: '/broadband_bot/bot_questions/' + guid + '/check',
      success: function (data) {
        if (data.complete == true) {
          $('.user_response_holder').html(data.user_response);
          $('.quetion-form-holder').removeClass('d-none');
          $('.similar_questions').removeClass('d-none');
        } else if (
          data.complete == false &&
          botCheckTimes <= maxBotCheckTimes
        ) {
          botCheckTimes = botCheckTimes + 1;
          poll(guid);
        } else {
          $('.user_response_holder').addClass('d-none');
          $('.user_response_issue_holder').removeClass('d-none');
          $('.quetion-form-holder').removeClass('d-none');
          $('.similar_questions').removeClass('d-none');
        }
      },
      dataType: 'json',
    });
  }, 500);
}

document.addEventListener('DOMContentLoaded', function () {
  if ($('.bot-answer').length == 0) {
    return;
  }
  var guid = $('.bot-answer').attr('data-question-guid');
  var complete = $('.bot-answer').attr('data-response-complete');
  console.log(guid.length);
  window.question_tries = 0;
  poll(guid);
});
